import React from "react";
import {
  ActivityIndicator,
  Image,
  Text,
  View,
  Animated,
  Easing,
  Button,
  Modal,
  Pressable, Linking, TouchableWithoutFeedback, BackHandler
} from 'react-native';
import { DrawerNavigationProp} from "@react-navigation/drawer";
import {MapImperativeHandleType, MapPOICategoryType, MapPOIType} from "../Types";
import SuperCluster from 'supercluster';
import {
  calculateCoordinatesDistance,
  findNearestMarker,
  get4PointsAroundCircumference, getLatLngWithFallback,
  navigateToLatLng
} from "../toolkits/mapHelpers";
import MapView, {Marker, Cluster} from "../templates/MapView";
import {BBox} from "geojson";
import {LocationObject} from "expo-location";
import {MapFiltersContext, RadiusFiltersContext} from "../Contexts";
import {COLOR_ACCENT} from "react-native-dotenv";
import * as Location from "expo-location";
import CheckBox from "expo-checkbox";
import MultiSlider from "@ptomasroos/react-native-multi-slider";
import {MaterialCommunityIcons} from "@expo/vector-icons";
import {PanGestureHandler, State} from "react-native-gesture-handler";

const Map: React.FC<{
  navigation: DrawerNavigationProp<any, any>
}> = ({navigation}) => {
  const [filters, setFilters] = React.useContext(MapFiltersContext);
  const [radius] = React.useContext(RadiusFiltersContext);
  const mapViewRef = React.useRef<MapImperativeHandleType>();
  const [mapReady, setMapReady] = React.useState(true);
  const [userLocation, setUserLocation] = React.useState<LocationObject>();
  const [selectedMarker, setSelectedMarker] = React.useState('');
  const [locationsPOIs, setLocationsPOIs] = React.useState<MapPOIType[]>([]);
  const [superCluster, setSuperCluster] = React.useState<SuperCluster<SuperCluster.AnyProps, SuperCluster.AnyProps>>();
  const [clusters, setClusters] = React.useState<(SuperCluster.ClusterFeature<SuperCluster.AnyProps> | SuperCluster.PointFeature<SuperCluster.AnyProps>)[]>([]);
  const [bounds, setBounds] = React.useState<BBox>();
  const [zoom, setZoom] = React.useState(8);
  const [POIs, setPOIs] = React.useState<MapPOIType[]>([]);
  const [filteredPOIs, setFilteredPOIs] = React.useState<MapPOIType[]>([]);
  const isMounted = React.useRef(false);
  const POICardTranslateYAnimatedValue = React.useRef(new Animated.Value(500)).current;
  const oldSwipePositionRef = React.useRef({x: 0, y: 0});
  const [foregroundLocationPermissions, requestPermission] = Location.useForegroundPermissions();

  React.useEffect(() => {
    isMounted.current = true;
    return () => { isMounted.current = false; }
  }, []);

  React.useEffect(() => {
    const backAction = () => { if (selectedMarker) clearSelection(); else navigation.goBack(); return true; };
    const backHandler = BackHandler.addEventListener("hardwareBackPress", backAction);
    return () => backHandler.remove();
  }, [selectedMarker]);

  React.useEffect(() => {
    (async () => {
      try {
        let response = await (await fetch('https://app.varie.me/index.php?api=map_markers', {
          method: 'GET',
        })).json();
        setPOIs(response);
      } catch (e) {
        console.error(e)
      }

      let locationPermissionResponse = {granted: false};
      if (foregroundLocationPermissions?.status !== 'granted') locationPermissionResponse = await requestPermission();

      if (foregroundLocationPermissions?.granted || locationPermissionResponse.granted) {
        setTimeout(async () => {
          await Location.enableNetworkProviderAsync();
          const phoneLocation = await Location.getCurrentPositionAsync();
          setUserLocation(phoneLocation);
        }, 300);
        }
      })();
  }, []);

  React.useEffect(() => {
    if (!POIs.length) return;
    POIs.forEach((poi) => {
      poi.categories.forEach((category) => setFilters( (f: { name: MapPOICategoryType, value: boolean }[]) => [
        ...f,
        ...f.find(({name}) => name === category) ? [] : [{ name: category, value: true }]
      ]));
    });
  }, [POIs]);

  React.useEffect(() => {
    if (!POIs.length) return;
    const activeCategories = filters.filter((v) => v.value).map((v) => v.name);
    setFilteredPOIs(POIs.filter((poi) => activeCategories.some((activeCategory) => {
      const { latitude, longitude } = getLatLngWithFallback(userLocation?.coords);
      return (
        poi.categories.includes(activeCategory)
        && (radius == 21 || !userLocation || (calculateCoordinatesDistance(latitude, longitude, poi.coordinates.latitude, poi.coordinates.longitude) < radius))
      );
    })))
  }, [filters, radius, POIs, userLocation]);

  React.useEffect(() => {
    if (!bounds || !filteredPOIs) return;
    const sc = new SuperCluster({ extent: 256 });
    sc.load(filteredPOIs.map((poi) => ({
      type: "Feature",
      properties: { cluster: false, poi: poi },
      geometry: {
        type: "Point",
        coordinates: [poi.coordinates.longitude, poi.coordinates.latitude],
      },
    })));
    setSuperCluster(sc);
    setClusters(sc.getClusters(bounds, zoom));
  }, [filteredPOIs, bounds, zoom]);

  React.useEffect(() => clearSelection, [radius, filters]);

  React.useEffect(() => {
    if (!userLocation) return;
    if (radius == 21) {
      let closestMarker = findNearestMarker(userLocation?.coords, filteredPOIs.map((poi) => poi.coordinates));
      mapViewRef.current?.fitToCoordinates([userLocation?.coords, closestMarker]);
    } else {
      mapViewRef.current?.fitToCoordinates(get4PointsAroundCircumference(userLocation.coords, radius));
    }
  }, [radius, userLocation]);

  const clearSelection = React.useCallback(() => {
    if (isMounted.current) Animated.timing(POICardTranslateYAnimatedValue, { toValue: 500, easing: Easing.cubic, duration: 200, useNativeDriver: false }).start(() => {
      setSelectedMarker("");
      setLocationsPOIs([]);
    });
  }, []);

  const onClusterPress = React.useCallback((cluster: SuperCluster.ClusterFeature<SuperCluster.AnyProps> | SuperCluster.PointFeature<SuperCluster.AnyProps>) => {
    const markers = superCluster?.getChildren(Number(cluster.id)); //Infinity?
    if (!markers) return;
    const isMarkersOnTheSameSpot = markers.every((marker) => (
      marker.geometry.coordinates[0] === markers[0].geometry.coordinates[0] &&
      marker.geometry.coordinates[1] === markers[0].geometry.coordinates[1]
    ));
    if (isMarkersOnTheSameSpot) {
      setSelectedMarker(`cluster_${cluster.id}`);
      setLocationsPOIs(markers
          .map((marker) => marker.properties.poi)
        // .sort((a, b) => a.available == b.available ? 0 : a.available ? -1 : 1)
      );
      mapViewRef.current?.centerMap(markers[0].properties.poi.coordinates, 0, 50);
    } else {
      clearSelection();
      mapViewRef.current?.fitToCoordinates(markers.map((marker) => ({
        longitude: marker.geometry.coordinates[0],
        latitude: marker.geometry.coordinates[1]
      })));
    }
  }, [superCluster]);

  React.useEffect(() => {
    if (selectedMarker && isMounted.current) {
      Animated.timing(POICardTranslateYAnimatedValue, { toValue: 0, easing: Easing.cubic, duration: 200, useNativeDriver: false }).start();
    }
  }, [selectedMarker])

  return (
    <View style={{ alignItems: 'center', height: '100%' }}>
      <MapView
        ref={mapViewRef}
        onMapReady={() => setMapReady(true)}
        filteredPOIs={filteredPOIs}
        onSelectPOI={(poi) => navigation.navigate('Test', {poi})}
        onMapPressEmpty={clearSelection}
        setBounds={setBounds}
        setZoom={setZoom}
        zoomDefault={zoom}
        regionDefault={{ latitude: 52.6326, longitude: 5.2913, latitudeDelta: 4, longitudeDelta: 3 }}
        padding={{ top: 150, right: 50, bottom: 75, left: 50 }}
        style={{ width: '100%', flex: 1 }}
        userLocation={userLocation}
        radius={radius}
      >
        {clusters?.map((cluster) => {
          const { cluster: isCluster } = cluster.properties;
          return (isCluster) ? (
            <Cluster
              key={`cluster_${cluster.id}`}
              isSelected={selectedMarker === `cluster_${cluster.id}`}
              cluster={cluster}
              onPress={() => onClusterPress(cluster)}
            />
          ) : (
            <Marker
              key={`vehicle_${cluster.properties.poi.id}`}
              isSelected={selectedMarker === `marker_${cluster.properties.poi.id}`}
              poi={cluster.properties.poi}
              onPress={() => {
                mapViewRef.current?.centerMap(cluster.properties.poi.coordinates, 0, 50);
                setSelectedMarker(`marker_${cluster.properties.poi.id}`);
                setLocationsPOIs([cluster.properties.poi]);
              }}
            />
          );
        })}
      </MapView>
      {!mapReady ? (
        <ActivityIndicator size="large" style={{ position: 'absolute', top: 200 }} />
      ) : null}
      <View style={{position: 'absolute', bottom: 0, width: '100%', maxWidth: 500, overflow: 'hidden'}}>
        <PanGestureHandler
          onHandlerStateChange={async (e) => {
            if (e.nativeEvent.state === State.BEGAN) {
              oldSwipePositionRef.current = {x: e.nativeEvent.x, y: e.nativeEvent.y};
            } else if (e.nativeEvent.state === State.END && selectedMarker) {
              const deltaY = e.nativeEvent.y - oldSwipePositionRef.current.y;
              if (deltaY > 100) clearSelection();
              else Animated.timing(POICardTranslateYAnimatedValue, { toValue: 0, easing: Easing.cubic, duration: 200, useNativeDriver: false }).start();
            }
          }}
          onGestureEvent={(e) => {
            const deltaY = e.nativeEvent.y - oldSwipePositionRef.current.y;
            if (deltaY > 0) POICardTranslateYAnimatedValue.setValue(deltaY);
          }}
        >
          <View style={{flex: 1}}>
            <Animated.View
              style={[{
                transform: [{translateY: POICardTranslateYAnimatedValue}],
                padding: POICardTranslateYAnimatedValue.interpolate({inputRange: [0, 500], outputRange: [20, 0]}),
                marginBottom: POICardTranslateYAnimatedValue.interpolate({inputRange: [0, 500], outputRange: [30, 0]}),
              }, {marginHorizontal: 10, backgroundColor: 'white'}]}
            >
              {locationsPOIs.map((poi) => (
                <View key={`poi_${poi.id}`}>
                  <Image source={{uri: poi.image}} style={{flex: 1, height: 200, resizeMode: 'cover'}}/>
                  <Text style={{fontWeight: 'bold', marginVertical: 5}}>{poi.name}</Text>
                  <View style={{flexDirection: 'row'}}>
                    {poi.categories.map((category, i) => (
                      <View key={`category_${i}`}
                            style={{backgroundColor: 'lightgray', marginRight: 5, paddingHorizontal: 5}}>
                        <Text style={{fontStyle: 'italic', textTransform: 'capitalize'}}>{category}</Text>
                      </View>
                    ))}
                  </View>
                  <Text style={{marginVertical: 5}}>{poi.description}</Text>
                  <View style={{flexDirection: 'row', justifyContent: 'flex-end'}}>
                    <Button
                      title="     Website     "
                      onPress={() => Linking.openURL(poi.website)}
                      color={COLOR_ACCENT}
                    />
                    <View style={{width: 5}}/>
                    <Button
                      title="     Navigate     "
                      onPress={() => navigateToLatLng(undefined, undefined, poi.coordinates.latitude, poi.coordinates.longitude)}
                      color={COLOR_ACCENT}
                    />
                  </View>
                </View>
              ))}
            </Animated.View>
          </View>
        </PanGestureHandler>
      </View>
    </View>
  );
}

const MapHeader: React.FC = () => {
  const [filters, setFilters] = React.useContext(MapFiltersContext);
  const [radius, setRadius] = React.useContext(RadiusFiltersContext);
  const [showFiltersModal, setShowFiltersModal] = React.useState(false);
  const [foregroundLocationPermissions, requestPermission] = Location.useForegroundPermissions();
  const [userLocation, setUserLocation] = React.useState<LocationObject>();

  const requestLocation =  React.useCallback(async () => {
    await requestPermission();
    if (foregroundLocationPermissions?.granted) {
      setTimeout(async () => {
        await Location.enableNetworkProviderAsync();
        const phoneLocation = await Location.getCurrentPositionAsync();
        setUserLocation(phoneLocation);
      }, 300);
    } else {
      setUserLocation(undefined);
    }
  }, []);

  React.useEffect(() => {
    if (foregroundLocationPermissions?.granted) {
      setTimeout(async () => {
        await Location.enableNetworkProviderAsync();
        const phoneLocation = await Location.getCurrentPositionAsync();
        setUserLocation(phoneLocation);
      }, 300);
    } else {
      setUserLocation(undefined);
    }
  }, [foregroundLocationPermissions?.granted]);

  return (
    <View style={{marginRight: 10, flexDirection: 'row'}}>
      {userLocation == undefined ? (
        <Pressable style={{
          backgroundColor: COLOR_ACCENT,
          justifyContent: 'center',
          paddingHorizontal: 10,
          borderRadius: 2,
          marginHorizontal: 5
        }} onPress={requestLocation}>
          <MaterialCommunityIcons name="crosshairs-gps" color="white" size={15}/>
        </Pressable>
      ) : null}
      <Button title="Filters" color={COLOR_ACCENT} onPress={() => setShowFiltersModal(true)}/>
      <Modal
        animationType="fade"
        transparent={true}
        statusBarTranslucent={true}
        visible={showFiltersModal}
        onRequestClose={() => setShowFiltersModal(false)}
      >
        <Pressable onPress={() => setShowFiltersModal(false)} style={{justifyContent: 'center', alignItems: 'center', flex: 1, backgroundColor: 'rgba(0, 0, 0, 0.4)'}}>
          <TouchableWithoutFeedback>
            <View style={{padding: 20, backgroundColor: 'white', borderRadius: 5, width: 370}}>
              <View>
                <Text style={{fontWeight: 'bold'}}>Sector:</Text>
                <View style={{flexDirection: 'row', flexWrap: 'wrap', alignItems: 'flex-start'}}>
                  {filters.filter((f) => ['public', 'private'].includes(f.name))
                    .map((filter) => (
                      <FilterCheckbox key={`filter_${filter.name}`} filter={filter} setter={setFilters} />
                    ))}
                </View>
              </View>
              <View>
                <Text style={{fontWeight: 'bold'}}>Categories:</Text>
                <View style={{flexDirection: 'row', flexWrap: 'wrap', alignItems: 'flex-start'}}>
                  {filters.filter((f) => !['public', 'private'].includes(f.name))
                    .map((filter) => (
                      <FilterCheckbox key={`filter_${filter.name}`} filter={filter} setter={setFilters}/>
                    ))}
                </View>
              </View>
              <View>
                <MultiSlider
                  min={1}
                  max={21}
                  step={1}
                  enabledOne={userLocation != undefined}
                  values={[userLocation != undefined ? radius : 21]}
                  onValuesChange={(data) => {
                    setRadius(data[0]);
                  }}
                  selectedStyle={{backgroundColor: 'lightgray'}}
                  isMarkersSeparated={true}
                  customMarkerLeft={() => (
                    <Text style={{width: 50, padding: 5, borderRadius: 6, backgroundColor: userLocation != undefined ? COLOR_ACCENT : 'lightgray', color: 'white', textAlign: 'center'}}>
                      {radius === 21 || userLocation == undefined ? '∞' : radius} km
                    </Text>
                  )}
                  containerStyle={{marginHorizontal: 25}}
                />
                {userLocation == undefined ? (
                  <Pressable
                    style={{position: 'absolute', left: 0, top: 0, width: '100%', height: '100%', zIndex: 1}}
                    onPress={requestLocation}
                  />
                ) : null}
              </View>
              <Button
                title="     close     "
                onPress={() => setShowFiltersModal(false)}
                color={COLOR_ACCENT}
              />
            </View>
          </TouchableWithoutFeedback>
        </Pressable>
      </Modal>
    </View>
  );
}

const FilterCheckbox = ({filter, setter}: { filter: {name: MapPOICategoryType, value: boolean}, setter: (v: any) => void }) => {
  const toggleCheckHandler = React.useCallback(() => setter((f: { name: MapPOICategoryType, value: boolean }[]) =>
    f.map((v) => (
      {name: v.name, value: v.name == filter.name ? !v.value : v.value}
    ))
  ), []);

  return (
    <Pressable
      style={{flexDirection: 'row', paddingVertical: 10, width: '50%'}}
      onPress={toggleCheckHandler}
    >
      <CheckBox value={filter.value} onChange={toggleCheckHandler}/>
      <Text style={{textTransform: 'capitalize', marginLeft: 5}}>{filter.name}</Text>
    </Pressable>
  )
};

export default Map;
export { MapHeader };
