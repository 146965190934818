import React from "react";
import {GOOGLE_MAPS_KEY} from 'react-native-dotenv';
import {Pressable, StyleSheet, View, Text, StyleProp} from "react-native";
import {GoogleMap, useJsApiLoader, OverlayView, Marker as GMarker, Circle} from "@react-google-maps/api";
import FontAwesome from "@expo/vector-icons/FontAwesome";
import {MapImperativeHandleType, MapPOIType} from "../Types";
import {LocationObject} from "expo-location";
import Svg, {Path} from "react-native-svg";
import {Circle as SCircle} from "react-native-svg/src/ReactNativeSVG";
import MapMarkerIcon from "./MapMarkerIcon";

const MapView: React.FC<{
  ref: any
  filteredPOIs: MapPOIType[]
  onMapReady: () => void
  onSelectPOI: (v: MapPOIType) => void
  onMapPressEmpty: () => void
  setBounds: (v: any) => void
  setZoom: (v: number) => void
  zoomDefault: number
  regionDefault: { latitude: number, longitude: number, latitudeDelta: number, longitudeDelta: number }
  padding: { top: number, right: number, bottom: number, left: number }
  style: StyleProp<any>
  userLocation?: LocationObject
  radius?: number
  children: JSX.Element | JSX.Element[]
}> = React.forwardRef((
  {
    children,
    filteredPOIs,
    onMapReady,
    onSelectPOI,
    onMapPressEmpty,
    setBounds,
    setZoom,
    zoomDefault,
    regionDefault,
    padding,
    style,
    userLocation,
    radius,
  }, ref) => {
  const mapRef = React.useRef<google.maps.Map>();
  const isMounted = React.useRef(false);
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: GOOGLE_MAPS_KEY
  });
  React.useEffect(() => {
    isMounted.current = isLoaded;
    return () => { isMounted.current = false; }
  }, [isLoaded]);

  React.useImperativeHandle(ref, (): MapImperativeHandleType => ({
    centerMap,
    fitToCoordinates
  }));

  const centerMap = React.useCallback((coordinates: {latitude: number, longitude: number}, offsetX = 0, offsetY = 0) => {
    const {latitude: lat, longitude: lng} = coordinates;
    mapRef.current?.panTo({lat, lng});
    mapRef.current?.panBy(offsetX, offsetY);
  }, []);

  const fitToCoordinates = React.useCallback((coordinates: {latitude: number, longitude: number}[]) => {
    if (!window.google) return;
    const bounds = new window.google.maps.LatLngBounds();
    coordinates.map((coordinate) => bounds.extend(
      new window.google.maps.LatLng(coordinate.latitude, coordinate.longitude)
    ))
    mapRef.current?.fitBounds(bounds, padding);
  }, []);

  return isLoaded ? (
    <GoogleMap
      mapContainerStyle={style}
      onLoad={(map) => {
        map.setZoom(zoomDefault);
        map.setCenter({lat: regionDefault.latitude, lng: regionDefault.longitude});
        mapRef.current = map;
        onMapReady();
      }}
      onUnmount={() => mapRef.current = undefined}
      onClick={onMapPressEmpty}
      options={{
        zoomControl: false,
        mapTypeControl: false,
        scaleControl: false,
        streetViewControl: false,
        rotateControl: false,
        fullscreenControl: false,
        clickableIcons: false,
        gestureHandling: 'greedy',
      }}
      onBoundsChanged={() => {
        if (mapRef.current) {
          const cBounds = mapRef.current?.getBounds();
          if (cBounds) setBounds([
            cBounds?.getSouthWest().lng(),
            cBounds?.getSouthWest().lat(),
            cBounds?.getNorthEast().lng(),
            cBounds?.getNorthEast().lat()
          ]);
        }
      }}
      onZoomChanged={() => {
        if (mapRef.current) {
          setZoom(mapRef.current?.getZoom() || 5);
        }
      }}
    >
      {children}
      {userLocation ? (
        <GMarker
          icon={{
            url: require('../../assets/images/marker-google-maps-current-location.png'),
            // @ts-ignore
            scaledSize: new window.google.maps.Size(20, 20),
            // @ts-ignore
            anchor: new window.google.maps.Point(10, 10)
          }}
          position={{lat: userLocation?.coords.latitude, lng: userLocation?.coords.longitude}}
        />
      ) : null}
      {radius && radius != 21 && userLocation ? (
        <Circle
          center={{
            lat: userLocation.coords.latitude,
            lng: userLocation.coords.longitude
          }}
          radius={radius * 1000}
          options={{
            fillColor: "rgba(68, 168, 83, 1)",
            // @ts-ignore
            strokeWidth: 1,
            strokeColor: "rgba(68, 168, 83, 0.6)"
          }}
        />
      ) : null}
    </GoogleMap>
  ) : null;
});

const Marker = ({poi, isSelected, onPress, iconSize = 50} : {poi: MapPOIType, isSelected: boolean, onPress: () => any, iconSize?: number}) => {
  return (
    <OverlayView
      position={{ lat: poi.coordinates.latitude, lng: poi.coordinates.longitude }}
      getPixelPositionOffset={() => ({ x: -(iconSize / 2), y: -iconSize })}
      mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
    >
      <Pressable onPress={onPress} style={{ width: iconSize, height: iconSize, justifyContent: "flex-end", alignItems: "center" }}>
        <Svg width={iconSize} height={iconSize} fill={!isSelected ? 'black' : 'green'} stroke="black" viewBox="-80 -20 550 550">
          <Path
            d="M172.268 501.67C26.97 291.031 0 269.413 0 192 0 85.961 85.961 0 192 0s192 85.961 192 192c0 77.413-26.97 99.031-172.268 309.67-9.535 13.774-29.93 13.773-39.464 0zM192 272c44.183 0 80-35.817 80-80s-35.817-80-80-80-80 35.817-80 80 35.817 80 80 80z"
            strokeWidth={!isSelected ? '0' : '40'}
          />
          <SCircle cx="35%" cy="35%" r="130" fill="white" />
          <MapMarkerIcon poiCategories={poi.categories} />
        </Svg>
      </Pressable>
    </OverlayView>
  );
}

const Cluster = ({ cluster, isSelected, onPress, iconSize = 50 }: { cluster: any, isSelected: boolean, onPress: () => any, iconSize?: number }) => {
  // const markers = superClusterRef.getLeaves(cluster.id, Infinity);
  // const allMarkersUnavailable = markers.every((marker) => !marker.vehicle.available);

  return (
    <OverlayView
      position={{ lat: cluster.geometry.coordinates[1], lng: cluster.geometry.coordinates[0] }}
      getPixelPositionOffset={() => ({ x: -(iconSize / 2), y: -iconSize })}
      mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
    >
      <Pressable onPress={onPress} style={{ width: iconSize, height: iconSize, justifyContent: "flex-end", alignItems: "center" }}>
        {isSelected ? (
          <FontAwesome name="arrow-down" color="#228519" size={20} />
        ) : null}
        <View style={[styles.clusterTextWrapper, {top: iconSize / 4.5}]}>
          <Text style={{fontWeight: 'bold'}}>{cluster.properties.point_count}</Text>
        </View>
        <Svg width={iconSize} height={iconSize} fill={!isSelected ? 'black' : 'green'} stroke="black" viewBox="-80 -20 550 550">
          <Path
            d="M172.268 501.67C26.97 291.031 0 269.413 0 192 0 85.961 85.961 0 192 0s192 85.961 192 192c0 77.413-26.97 99.031-172.268 309.67-9.535 13.774-29.93 13.773-39.464 0zM192 272c44.183 0 80-35.817 80-80s-35.817-80-80-80-80 35.817-80 80 35.817 80 80 80z"
            strokeWidth={!isSelected ? '0' : '40'}
          />
          <SCircle cx="35%" cy="35%" r="130" fill="white" />
        </Svg>
      </Pressable>
    </OverlayView>
  );
}

const styles = StyleSheet.create({
  clusterTextWrapper: {
    position: "absolute",
    zIndex: 1,
    width: '100%',
    alignItems: 'center',
  },
});

export default MapView;
export { Marker, Cluster };
