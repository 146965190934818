import React from "react";
import {Button, View} from "react-native"
import {Host} from "react-native-portalize";
import Contexts from "./src/Contexts";
import {NavigationContainer} from '@react-navigation/native';
import {
  createDrawerNavigator,
  DrawerContentComponentProps,
  DrawerContentScrollView,
  DrawerItemList
} from "@react-navigation/drawer";
import Home, {HomeHeader} from "./src/screens/Home";
import Game from "./src/screens/Game";
import Map, {MapHeader} from "./src/screens/Map";
import * as Facebook from 'expo-auth-session/providers/facebook';
import * as Google from 'expo-auth-session/providers/google';
import {COLOR_ACCENT} from 'react-native-dotenv';
import * as WebBrowser from 'expo-web-browser';

const DrawerNavigator = createDrawerNavigator();

export default () => {
  return (
    <Host>
      <Contexts>
        <NavigationContainer>
          <DrawerNavigator.Navigator initialRouteName={process.env.NODE_ENV == 'production' ? 'Home' : 'Map'}  drawerContent={props => <CustomDrawer {...props} />}>
            <DrawerNavigator.Screen name="Home" component={Home} options={{unmountOnBlur: true, headerRight: () => <HomeHeader />}}/>
            <DrawerNavigator.Screen name="Game" component={Game} options={{unmountOnBlur: true}}/>
            <DrawerNavigator.Screen name="Map" component={Map} options={{unmountOnBlur: true, headerRight: () => <MapHeader />}}/>
          </DrawerNavigator.Navigator>
        </NavigationContainer>
      </Contexts>
    </Host>
  );
}

WebBrowser.maybeCompleteAuthSession();

const CustomDrawer = (props: DrawerContentComponentProps) => {
  const [FBRequest, FBResponse, FBPromptAsync] = Facebook.useAuthRequest({
    clientId: '175242767066'
  });
  const [GRequest, GResponse, GPromptAsync] = Google.useAuthRequest({
    expoClientId: '427977014862-5i8istrtrjd8k1vj9gt7vi992aqumofo.apps.googleusercontent.com',
    iosClientId: '427977014862-5i8istrtrjd8k1vj9gt7vi992aqumofo.apps.googleusercontent.com',
    androidClientId: '427977014862-7kmnhucfli3gsrh8cl3n0nqs2dbcqg09.apps.googleusercontent.com',
    webClientId: '427977014862-5i8istrtrjd8k1vj9gt7vi992aqumofo.apps.googleusercontent.com',
  });
  React.useEffect(() => {
    (async () => {
      if (FBResponse?.type === 'success') {
        const { authentication } = FBResponse;
        const rs = await (await fetch(`https://graph.facebook.com/me?fields=email&access_token=${authentication?.accessToken}`)).json();
        alert(rs.email);
      }
    })()
  }, [FBResponse]);
  React.useEffect(() => {
    (async () => {
      if (GResponse?.type === 'success') {
        const { authentication } = GResponse;
        const rs = await (await fetch(`https://www.googleapis.com/oauth2/v3/userinfo?access_token=${authentication?.accessToken}`)).json();
        alert(rs.email);
      }
    })()
  }, [GResponse]);

  return (
      <DrawerContentScrollView {...props}>
        <View style={{flexDirection: 'row', paddingHorizontal: 5, justifyContent: 'center'}}>
          <Button title="fb login" disabled={!FBRequest} onPress={() => {FBPromptAsync();}} color={COLOR_ACCENT} />
          <View style={{width: 5}}/>
          <Button title="g login" disabled={!GRequest} onPress={() => {GPromptAsync();}} color={COLOR_ACCENT} />
        </View>
        <DrawerItemList {...props} />
      </DrawerContentScrollView>
  );
};
