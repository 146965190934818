import React from "react";
import {COLOR_ACCENT} from 'react-native-dotenv';
import {DrawerNavigationProp} from "@react-navigation/drawer";
import {Alert, Button, Dimensions, Image, Modal, Pressable, SafeAreaView, ScrollView, StyleSheet, Text, TextInput, View} from "react-native";
import AsyncStorage from "@react-native-async-storage/async-storage";
import {Portal} from "react-native-portalize";
import {StatusBar} from "expo-status-bar";
import {LineChart} from "react-native-chart-kit";
import MultiSlider from "@ptomasroos/react-native-multi-slider";
import CheckBox from "expo-checkbox";
import {AddFundModalVisibleContext} from "../Contexts";

const Home: React.FC<{
  navigation: DrawerNavigationProp<any, any>
}> = () => {
  const [funds, setFunds] = React.useState<{buy: number, price: number, buy_fee_percent?: number}[]>([]);
  const [addFundModalVisible, setAddFundModalVisible] = React.useContext(AddFundModalVisibleContext);
  const [fundModalInputBuy, setFundModalInputBuy] = React.useState('');
  const [fundModalInputPrice, setFundModalInputPrice] = React.useState('');
  const [fundModalInputBuyFeePercent, setFundModalInputBuyFeePercent] = React.useState('');
  const [fakeCurrentPrice, setFakeCurrentPrice] = React.useState(0);
  const [excludeRevolutFee, setExcludeRevolutFee] = React.useState(true);
  const [excludeFundIndexes, setExcludeFundIndexes] = React.useState<number[]>([]);
  const [fundModalEditIndex, setFundModalEditIndex] = React.useState<number>();
  const interactingWithUI = React.useRef(false);

  const [showWebSplashScreen, setShowWebSplashScreen] = React.useState(true);
  const [currentPrice, setCurrentPrice] = React.useState(0);
  const [pastPrices, setPastPrices] = React.useState<number[]>([]);

  React.useEffect(() => {
    fetch(`https://homeassistant.poulou.gr/api/history/period/${(new Date(Date.now()-30*60*1000)).toISOString()}?filter_entity_id=sensor.binance_eth`)
      .then(response => response.json())
      .then(response => response[0])
      .then(response => {
        setShowWebSplashScreen(false);
        setPastPrices(response.reduce((acc: number[], curr: {state: string}) => [...acc, Number(curr.state)], []));
        setCurrentPrice(Number(response[response.length - 1].state));
      })
      .catch(err => console.error(err));
  }, []);

  React.useEffect(() => {
    setExcludeFundIndexes([]);
  }, [funds]);
  React.useEffect(() => {
    setFakeCurrentPrice(currentPrice);
  }, [currentPrice]);
  React.useEffect(() => {
    AsyncStorage.getItem('funds').then((fundsInStorage) => {
      if (fundsInStorage) setFunds(JSON.parse(fundsInStorage))
    });
  }, []);

  return showWebSplashScreen ? (
    <Portal>
      <View style={{backgroundColor: 'white', zIndex: 1, position: 'absolute', top: 0, left: 0, justifyContent: 'center', alignItems: 'center', width: Dimensions.get('window').width, height: Dimensions.get('window').height}}>
        <Image source={require('../../assets/splash.png')} style={{width: 256, height: 256}} />
      </View>
    </Portal>
  ) : (
    <SafeAreaView style={{flex: 1, paddingTop: 20}}>
      <ScrollView contentContainerStyle={styles.container} keyboardShouldPersistTaps="always">
        <StatusBar style="auto"/>
        <Text style={{fontSize: 22, fontWeight: 'bold'}}>{currentPrice} €</Text>
        {pastPrices.length ? (
          <LineChart
            data={{labels: [], datasets: [{data: pastPrices}]}}
            width={330}
            height={150}
            yAxisSuffix=" €"
            chartConfig={{
              backgroundColor: "black",
              decimalPlaces: 2, // optional, defaults to 2dp
              color: (opacity = 1) => `rgba(255, 255, 255, ${opacity})`,
              propsForDots: {
                r: "2",
                strokeWidth: "2",
                stroke: "green"
              }
            }}
            bezier
            style={{
              marginVertical: 8,
              borderRadius: 5,
              backgroundColor: 'black',
              paddingHorizontal: 10,
            }}
          />
        ) : (
          <View style={{ backgroundColor: 'black', borderRadius: 5, width: 330, height: 150, justifyContent: 'center', alignItems: 'center' }}>
            <Text style={{color: 'white'}}>Loading..</Text>
          </View>
        )}
        {currentPrice > 0 ? (
          <View style={{ borderWidth: 1, borderRadius: 5, borderColor: 'lightgray', padding: 10, marginTop: 20 }}>
            <View style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', marginBottom: 5 }}>
              <Text style={{color: 'gray'}}>ETH: {fakeCurrentPrice.toFixed(2)} €</Text>
              <View style={{ flexDirection: 'row' }}>
                <Text style={{ paddingHorizontal: 5 }}>Exclude revolut fee (1.49%)</Text>
                <CheckBox
                  color={COLOR_ACCENT}
                  value={excludeRevolutFee}
                  onValueChange={() => setExcludeRevolutFee(!excludeRevolutFee)}
                />
              </View>
            </View>
            <View>
              <View style={{flexDirection: 'row', marginBottom: 5}}>
                <Text style={{ textAlign: 'center', paddingHorizontal: 5, fontWeight: 'bold', width: 60, marginLeft: 25}}>Buys</Text>
                <Text style={{ textAlign: 'center', paddingHorizontal: 5, fontWeight: 'bold', width: 90}}>Price</Text>
                <Text style={{ textAlign: 'center', paddingHorizontal: 5, fontWeight: 'bold', width: 140}}>Performance</Text>
              </View>
            </View>
            {funds.map(({buy, price, buy_fee_percent}, index) => {
              const buyFeeFactor = (100 - Number(buy_fee_percent || 1.49)) / 100;
              let currentValue = Number(fakeCurrentPrice || currentPrice) * (buy / price * buyFeeFactor);
              if (excludeRevolutFee) currentValue *= (100 - 1.49) / 100;
              return (
                <View key={index} style={{flexDirection: 'row', marginBottom: 5, alignItems: 'center', backgroundColor: excludeFundIndexes.includes(index) ? 'lightgray' : 'transparent'}}>
                  <CheckBox
                    color={COLOR_ACCENT}
                    style={{ width: 20, marginRight: 5 }}
                    value={!excludeFundIndexes.includes(index)}
                    onValueChange={(isChecked) => !isChecked
                      ? setExcludeFundIndexes([...excludeFundIndexes, index])
                      : setExcludeFundIndexes(excludeFundIndexes.filter((efi) => efi != index))
                    }
                  />
                  <Text style={{width: 60, borderWidth: 1, textAlign: 'right', paddingHorizontal: 5, paddingVertical: 5, borderRightWidth: 0}}>{buy} €</Text>
                  <Text style={{width: 100, borderWidth: 1, textAlign: 'right', paddingHorizontal: 5, paddingVertical: 5, borderRightWidth: 0}}>{price} € </Text>
                  <Text style={{width: 70, borderWidth: 1, textAlign: 'right', paddingHorizontal: 5, paddingVertical: 5, borderRightWidth: 0}}>{currentValue.toFixed(2)} €</Text>
                  <Text style={{width: 70, borderWidth: 1, textAlign: 'right', paddingHorizontal: 5, paddingVertical: 5}}>{(100 * currentValue / buy).toFixed(2)} %</Text>
                  <Pressable
                    style={{width: 30, paddingHorizontal: 5, alignItems: 'center'}}
                    onPress={() => setFundModalEditIndex(index)}
                  >
                    <Text style={{fontSize: 18, fontWeight: 'bold'}}>✎</Text>
                  </Pressable>
                </View>
              )
            })}
            <View style={{ flexDirection: 'row', width: 325 }}>
              <Text style={{ textAlign: 'right', fontWeight: 'bold', width: 85, paddingRight: 5 }}>
                {funds.reduce((prev, {buy}, currentIndex) => {
                  if (excludeFundIndexes.includes(currentIndex)) return prev;
                  return prev + buy;
                }, 0)} €
              </Text>
              <Text style={{ textAlign: 'right', fontWeight: 'bold', width: 170, paddingRight: 5 }}>
                {funds.reduce((prev, {buy, price, buy_fee_percent}, currentIndex) => {
                  if (excludeFundIndexes.includes(currentIndex)) return prev;
                  const buyFeeFactor = (100 - Number(buy_fee_percent || 1.49)) / 100;
                  let currentValue = Number(fakeCurrentPrice || currentPrice) * (buy / price * buyFeeFactor);
                  if (excludeRevolutFee) currentValue *= (100 - 1.49) / 100;
                  return prev + currentValue;
                }, 0).toFixed(2)} €
              </Text>
              <Text style={{ textAlign: 'right', fontWeight: 'bold', width: 70, paddingRight: 5 }}>
                {(100
                  * funds.reduce((prev, {buy, price, buy_fee_percent}, currentIndex) => {
                    if (excludeFundIndexes.includes(currentIndex)) return prev;
                    const buyFeeFactor = (100 - Number(buy_fee_percent || 1.49)) / 100;
                    let currentValue = Number(fakeCurrentPrice || currentPrice) * (buy / price * buyFeeFactor);
                    if (excludeRevolutFee) currentValue *= (100 - 1.49) / 100;
                    return prev + currentValue;
                  }, 0)
                  / funds.reduce((prev, {buy}, currentIndex) => {
                    if (excludeFundIndexes.includes(currentIndex)) return prev;
                    return prev + buy;
                  }, 0)
                ).toFixed(2)} %
              </Text>
            </View>
          </View>
        ) : null}
        <MultiSlider
          min={Number(fakeCurrentPrice) - 200}
          max={Number(fakeCurrentPrice) + 200}
          step={1}
          values={[fakeCurrentPrice]}
          onValuesChange={(data) => {
            setFakeCurrentPrice(data[0]);
          }}
          onValuesChangeStart={() => interactingWithUI.current = true}
          onValuesChangeFinish={() => {
            interactingWithUI.current = false;
            setFakeCurrentPrice(currentPrice);
          }}
          selectedStyle={{backgroundColor: 'lightgray'}}
          isMarkersSeparated={true}
          customMarkerLeft={() => (
            <Text style={{width: 80, padding: 5, borderRadius: 6, backgroundColor: COLOR_ACCENT, color: 'white', textAlign: 'center'}}>
              Fake it{"\n"}
              {fakeCurrentPrice.toFixed(2)} €
            </Text>
          )}
          containerStyle={{marginVertical: 10}}
        />
        <Modal
          animationType="fade"
          transparent={true}
          statusBarTranslucent={true}
          visible={addFundModalVisible || fundModalEditIndex !== undefined}
          onRequestClose={() => {
            setAddFundModalVisible(false);
            setFundModalEditIndex(undefined);
          }}
          onShow={() => {
            if (fundModalEditIndex === undefined) {
              setFundModalInputBuy('');
              setFundModalInputPrice('');
              setFundModalInputBuyFeePercent('');
            } else {
              setFundModalInputBuy(funds[fundModalEditIndex].buy.toString());
              setFundModalInputPrice(funds[fundModalEditIndex].price.toString());
              setFundModalInputBuyFeePercent(funds[fundModalEditIndex].buy_fee_percent?.toString() || "1.49");
            }
          }}
        >
          <View style={{justifyContent: 'center', alignItems: 'center', flex: 1, backgroundColor: 'rgba(0,0,0, 0.4)'}}>
            <View style={{padding: 20, backgroundColor: 'white', borderRadius: 5}}>
              <Text style={{fontSize: 15, fontWeight: 'bold', marginBottom: 10, textAlign: 'center'}}>{addFundModalVisible ? "Add" : "Edit"} fund</Text>
              <View style={{ borderWidth: 1, borderRadius: 5, borderColor: 'lightgray', paddingLeft: 15, paddingRight: 10, paddingVertical: 10, marginTop: 10 }}>
                <Text style={{backgroundColor: 'white', color: 'gray', position: 'absolute', top: -12, left: 5, paddingHorizontal: 3}}>Buy</Text>
                <TextInput
                  keyboardType='numeric'
                  onChangeText={(text) => setFundModalInputBuy(text)}
                  defaultValue={fundModalInputBuy}
                  maxLength={10}
                  style={{width: 250}}
                />
                <Text style={{ color: 'gray', position: 'absolute', top: 10, right: 32, fontSize: 20}}>€</Text>
              </View>
              <View style={{ borderWidth: 1, borderRadius: 5, borderColor: 'lightgray', paddingLeft: 15, paddingRight: 10, paddingVertical: 10, marginTop: 15 }}>
                <Text style={{backgroundColor: 'white', color: 'gray', position: 'absolute', top: -12, left: 5, paddingHorizontal: 3}}>Price</Text>
                <TextInput
                  keyboardType='numeric'
                  onChangeText={(text) => setFundModalInputPrice(text)}
                  defaultValue={fundModalInputPrice}
                  maxLength={10}
                />
                <Text style={{ color: 'gray', position: 'absolute', top: 10, right: 10, fontSize: 20}}>ETH</Text>
              </View>
              <View style={{ borderWidth: 1, borderRadius: 5, borderColor: 'lightgray', paddingLeft: 15, paddingRight: 10, paddingVertical: 10, marginVertical: 15 }}>
                <Text style={{backgroundColor: 'white', color: 'gray', position: 'absolute', top: -12, left: 5, paddingHorizontal: 3}}>Buy fee</Text>
                <TextInput
                  keyboardType='numeric'
                  onChangeText={(text) => setFundModalInputBuyFeePercent(text)}
                  defaultValue={fundModalInputBuyFeePercent}
                  maxLength={10}
                />
                <Text style={{ color: 'gray', position: 'absolute', top: 10, right: 32, fontSize: 20}}>%</Text>
              </View>
              <View style={{flexDirection: 'row', justifyContent: 'space-between', marginTop: 10}}>
                {addFundModalVisible ? (
                  <Button
                    title="     Add     "
                    onPress={() => {
                      if (fundModalInputBuy && fundModalInputPrice) {
                        const updatedFunds = [...funds, {
                          buy: Number(fundModalInputBuy),
                          price: Number(fundModalInputPrice),
                          buy_fee_percent: Number(fundModalInputBuyFeePercent)
                        }];
                        AsyncStorage.setItem('funds', JSON.stringify(updatedFunds)).then(() => {
                          setFunds(updatedFunds);
                          setAddFundModalVisible(false);
                        });
                      }
                    }}
                  />
                ) : (
                  <React.Fragment>
                    <Button
                      title="   delete   "
                      color="#fa3939"
                      onPress={() => (fundModalEditIndex) ? Alert.alert(
                        "Delete",
                        `Delete fund ${funds[fundModalEditIndex].buy} € for ${funds[fundModalEditIndex].price} €`,
                        [
                          {text: "Cancel", style: "cancel"},
                          {text: "OK", onPress: () => {
                              const updatedFunds = funds.filter((f, i) => i != fundModalEditIndex);
                              AsyncStorage.setItem('funds', JSON.stringify(updatedFunds)).then(() => {
                                setFunds(updatedFunds);
                                setFundModalEditIndex(undefined);
                              });
                            }}
                        ]
                      ) : null}
                    />
                    <Button
                      title="   update   "
                      onPress={() => {
                        if (fundModalInputBuy && fundModalInputPrice && fundModalInputBuyFeePercent) {
                          const updatedFunds = funds.map((f, i) => (i === fundModalEditIndex)
                            ? ({buy: Number(fundModalInputBuy), price: Number(fundModalInputPrice), buy_fee_percent: Number(fundModalInputBuyFeePercent)})
                            : f
                          );
                          AsyncStorage.setItem('funds', JSON.stringify(updatedFunds)).then(() => {
                            setFunds(updatedFunds);
                            setFundModalEditIndex(undefined);
                          });
                        }
                      }}
                    />
                  </React.Fragment>
                )}
                <Pressable style={{padding: 7}} onPress={() => {
                  setAddFundModalVisible(false);
                  setFundModalEditIndex(undefined);
                }}>
                  <Text style={{color: 'gray'}}>CANCEL</Text>
                </Pressable>
              </View>
            </View>
          </View>
        </Modal>
      </ScrollView>
    </SafeAreaView>
  );
}

const HomeHeader: React.FC = () => {
  const [, setAddFundModalVisible] = React.useContext(AddFundModalVisibleContext);

  return (
    <View style={{marginRight: 10}}>
      <Button title="Add fund" color={COLOR_ACCENT} onPress={() => setAddFundModalVisible(true)}/>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#fff',
    alignItems: 'center',
    justifyContent: 'center',
  },
});

export default Home;
export { HomeHeader };
