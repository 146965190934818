import React from "react";
import 'react-native-gesture-handler'
import {TextureLoader, THREE} from "expo-three";
import {PanGestureHandler, PinchGestureHandler} from "react-native-gesture-handler";
import {Vector3} from "three";

export function addBackgroundBox(
  scene: THREE.Scene,
  sideLength: number,
  {nx, ny, nz, px, py, pz}: { nx: string, ny: string, nz: string, px: string, py: string, pz: string }
): void {
  let meshFront = new THREE.Mesh(
    new THREE.PlaneGeometry(sideLength,sideLength),
    new THREE.MeshBasicMaterial( { map: new TextureLoader().load(nx) })
  );
  meshFront.position.setZ(-(sideLength / 2));
  scene.add(meshFront);
  let meshRight = new THREE.Mesh(
    new THREE.PlaneGeometry(sideLength,sideLength),
    new THREE.MeshBasicMaterial( { map: new TextureLoader().load(pz) })
  );
  meshRight.rotateY(THREE.MathUtils.degToRad(-90));
  meshRight.position.setX(sideLength / 2);
  scene.add(meshRight);
  let meshBack = new THREE.Mesh(
    new THREE.PlaneGeometry(sideLength,sideLength),
    new THREE.MeshBasicMaterial( { map: new TextureLoader().load(px) })
  );
  meshBack.rotateY(THREE.MathUtils.degToRad(-180));
  meshBack.position.setZ(sideLength / 2);
  scene.add(meshBack);
  let meshLeft = new THREE.Mesh(
    new THREE.PlaneGeometry(sideLength,sideLength),
    new THREE.MeshBasicMaterial( { map: new TextureLoader().load(nz) })
  );
  meshLeft.rotateY(THREE.MathUtils.degToRad(90));
  meshLeft.position.setX(-(sideLength / 2));
  scene.add(meshLeft);
  let meshUp = new THREE.Mesh(
    new THREE.PlaneGeometry(sideLength,sideLength),
    new THREE.MeshBasicMaterial( { map: new TextureLoader().load(ny) })
  );
  meshUp.rotateX(THREE.MathUtils.degToRad(90));
  meshUp.rotateZ(THREE.MathUtils.degToRad(-90));
  meshUp.position.setY(sideLength / 2);
  scene.add(meshUp);
  let meshDown = new THREE.Mesh(
    new THREE.PlaneGeometry(sideLength,sideLength),
    new THREE.MeshBasicMaterial( { map: new TextureLoader().load(py) })
  );
  meshDown.rotateX(THREE.MathUtils.degToRad(-90));
  meshDown.rotateZ(THREE.MathUtils.degToRad(90));
  meshDown.position.setY(-(sideLength / 2));
  scene.add(meshDown);

  // // for android (pixelated)
  // scene.background = await loadCubeTextureAsync({
  //   assetForDirection: {
  //     nx: require("./resources/posx.jpg"),
  //     ny: require("./resources/posy.jpg"),
  //     nz: require("./resources/posz.jpg"),
  //     px: require("./resources/negx.jpg"),
  //     py: require("./resources/posy.jpg"),
  //     pz: require("./resources/negz.jpg"),
  //   }
  // });
  // // for the web (doesn't work on android)
  // scene.background = new THREE.CubeTextureLoader().load([
  //   require("./resources/posx.jpg"),
  //   require("./resources/negx.jpg"),
  //   require("./resources/posy.jpg"),
  //   require("./resources/negy.jpg"),
  //   require("./resources/posz.jpg"),
  //   require("./resources/negz.jpg"),
  // ]);
  // // works everywhere
  // scene.background = new TextureLoader().load(require("./resources/posx.jpg"))
}

export function CustomOrbitControls({camera, scene, children}: {camera?: THREE.Camera, scene?: THREE.Scene, children: any} ) {
  const sphereLR = React.useRef<THREE.Mesh>();
  const sphereUD = React.useRef<THREE.Mesh>();
  const oldSwipePositionRef = React.useRef({x: 0, y: 0});
  const pinchGestureHandlerRef = React.useRef();
  const panGestureHandlerRef = React.useRef();
  const pinchIsActive = React.useRef(false);

  React.useEffect(() => {
    const onWheel = (e: WheelEvent) => {
      if (!camera) return;
      let zoomSpeed = 0.5;
      if (e.deltaY < 0) {
        camera.position.x *= Math.cos(zoomSpeed);
        camera.position.y *= Math.cos(zoomSpeed);
        camera.position.z *= Math.cos(zoomSpeed);
      } else {
        camera.position.x /= Math.cos(zoomSpeed);
        camera.position.y /= Math.cos(zoomSpeed);
        camera.position.z /= Math.cos(zoomSpeed);
      }
    }
    window.addEventListener("wheel", onWheel);

    return () => window.removeEventListener("wheel", onWheel);
  }, [camera]);

  React.useEffect(() => {
   if (!camera || !scene) return;

    const sphereGeometry = new THREE.SphereGeometry( 150, 5, 5 );
    const sphereMaterial = new THREE.MeshBasicMaterial( { wireframe: true } );
    sphereLR.current = new THREE.Mesh( sphereGeometry, sphereMaterial );
    sphereUD.current = new THREE.Mesh( sphereGeometry, sphereMaterial );
    sphereUD.current?.rotateZ(THREE.MathUtils.degToRad(90))
    scene.add( sphereLR.current );
    scene.add( sphereUD.current );

  }, [camera, scene]);

  return (
    <PinchGestureHandler
      ref={pinchGestureHandlerRef}
      simultaneousHandlers={panGestureHandlerRef}
      onGestureEvent={(e) => {
        if (!camera) return
        let zoomSpeed = 0.2;
        if (e.nativeEvent.scale > 1) {
          camera.position.x *= Math.cos(zoomSpeed);
          camera.position.y *= Math.cos(zoomSpeed);
          camera.position.z *= Math.cos(zoomSpeed);
        } else {
          camera.position.x /= Math.cos(zoomSpeed);
          camera.position.y /= Math.cos(zoomSpeed);
          camera.position.z /= Math.cos(zoomSpeed);
        }
      }}
      onHandlerStateChange={(e) => pinchIsActive.current = e.nativeEvent.numberOfPointers > 1}
    >
      <PanGestureHandler
        ref={panGestureHandlerRef}
        simultaneousHandlers={pinchGestureHandlerRef}
        onBegan={() => oldSwipePositionRef.current.x = Number(camera?.position.x)}
        onHandlerStateChange={(e) => pinchIsActive.current = e.nativeEvent.numberOfPointers > 1}
        onGestureEvent={(e) => {
          if (!camera || !sphereLR.current || !sphereUD.current || pinchIsActive.current) return
          const oldSwipePosition = oldSwipePositionRef.current;
          //https://github.com/josdirksen/threejs-cookbook/blob/master/03-camera/03.08-rotate-camera-around-scene-y-axis.html
          const rotSpeed = 0.1;
          scene?.attach(camera)
          // console.log(camera.position.z - camera.position.x)
          if (Math.abs(e.nativeEvent.x - oldSwipePosition.x) > 0.2) {
            sphereLR.current.attach(camera);
            if (e.nativeEvent.x < oldSwipePosition.x) {
              sphereLR.current.rotation.y += rotSpeed;
              sphereUD.current?.rotateOnWorldAxis(new Vector3(0, 1, 0), rotSpeed);
            } else {
              sphereLR.current.rotation.y -= rotSpeed;
              sphereUD.current?.rotateOnWorldAxis(new Vector3(0, 1, 0), -rotSpeed);
            }
          }
          else if (Math.abs(e.nativeEvent.y - oldSwipePosition.y) > 0.05) {
            sphereUD.current.attach(camera);
            if (e.nativeEvent.y > oldSwipePosition.y) { // UP
              sphereUD.current?.rotateY(rotSpeed / 2);
            } else { // DOWN
              sphereUD.current?.rotateY(-rotSpeed / 2);
            }
          }
          camera.lookAt(new THREE.Vector3());
          oldSwipePositionRef.current.x = e.nativeEvent.x;
          oldSwipePositionRef.current.y = e.nativeEvent.y;
        }}
      >
        {children}
      </PanGestureHandler>
    </PinchGestureHandler>
  );
}
