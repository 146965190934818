import React from "react";
import {GLTF, GLTFLoader} from "three/examples/jsm/loaders/GLTFLoader";
import {FBXLoader} from "three/examples/jsm/loaders/FBXLoader";
import {MTLLoader} from "three/examples/jsm/loaders/MTLLoader";
import {OBJLoader} from "three/examples/jsm/loaders/OBJLoader";
import {Group} from "three";

const gltfLoader = new GLTFLoader();
export async function loadGLTFAsync(url: string): Promise<GLTF> {
  return new Promise((resolve, reject) => {
    gltfLoader.load(url, data=> resolve(data), () => {}, reject);
  });
}

const fbxLoader = new FBXLoader();
export async function loadFBXAsync(url: string): Promise<Group> {
  return new Promise((resolve, reject) => {
    fbxLoader.load(url, data=> resolve(data), () => {}, reject);
  });
}

const mtlLoader = new MTLLoader();
export async function loadMTLAsync(url: string): Promise<MTLLoader.MaterialCreator> {
  return new Promise((resolve, reject) => {
    mtlLoader.load(url, data=> resolve(data), () => {}, reject);
  });
}

const objLoader = new OBJLoader();
export async function loadOBJAsync(url: string, materials: string | undefined = undefined): Promise<Group> {
  if (materials) objLoader.setMaterials(await loadMTLAsync(require('../../assets/models/sprinter-body-material.mtl')));

  return new Promise((resolve, reject) => {
    objLoader.load(url, data=> resolve(data), () => {}, reject);
  });
}
