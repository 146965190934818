import {MapPOICategoryType} from "../Types";
import {Linking, Platform} from "react-native";
import qs from "qs";

//https://stackoverflow.com/a/18883819
export const calculateCoordinatesDistance = (lat1: number, lon1: number, lat2: number, lon2: number) => {
  const toRad = (v: number) => v * Math.PI / 180;
  const R = 6371; // km
  const dLat = toRad(lat2-lat1);
  const dLon = toRad(lon2-lon1);
  lat1 = toRad(lat1);
  lat2 = toRad(lat2);

  const a = Math.sin(dLat/2) * Math.sin(dLat/2) +
    Math.sin(dLon/2) * Math.sin(dLon/2) * Math.cos(lat1) * Math.cos(lat2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a));

  return R * c;
}

export const getLatLngWithFallback = (
  location1: {latitude?: number, longitude?: number} | string | undefined,
  location2: {latitude?: number, longitude?: number} | undefined = undefined
) => {
  let latitude = 0, longitude = 0;
  if (typeof location1 === 'object' && location1?.latitude && location1?.longitude) {
    latitude = location1.latitude;
    longitude = location1.longitude;
  } else if (location2?.latitude && location2?.longitude) {
    latitude = location2.latitude;
    longitude = location2.longitude;
  }

  return { latitude, longitude }
};

export const findNearestMarker = (
  location: {latitude: number, longitude: number},
  markers: {latitude: number, longitude: number}[]
) => {
  let closestMarker = { distance: 9999, latitude: 0, longitude: 0 };
  markers?.map((marker) => {
    const currentDistance = calculateCoordinatesDistance(
      location.latitude, location.longitude,
      marker.latitude, marker.longitude
    );
    if (currentDistance < closestMarker.distance) {
      closestMarker = {
        distance: currentDistance,
        latitude: marker.latitude,
        longitude: marker.longitude
      };
    }
  });

  return closestMarker;
};

// https://stackoverflow.com/a/53681577
export const get4PointsAroundCircumference = (
  location: {latitude: number, longitude: number},
  radius: number
) => {
  const earthRadius = 6378.1; //Km
  const { latitude, longitude } = getLatLngWithFallback(location);
  const lat0 = latitude + (-radius / earthRadius) * (180 / Math.PI);
  const lat1 = latitude + (radius / earthRadius) * (180 / Math.PI);
  const lng0 = longitude + (-radius / earthRadius) * (180 / Math.PI) / Math.cos(latitude * Math.PI / 180);
  const lng1 = longitude + (radius / earthRadius) * (180 / Math.PI) / Math.cos(latitude * Math.PI / 180);

  return [
    {latitude: lat0, longitude: longitude}, //bottom
    {latitude: latitude, longitude: lng0}, //left
    {latitude: lat1, longitude: longitude}, //top
    {latitude: latitude, longitude: lng1} //right
  ]
};

export const navigateToLatLng = async (sLat?: number, sLng?: number, dLat?: number, dLng?: number, mode = "walking") => {
  const query = qs.stringify({
    saddr: (sLat && sLng) ? [sLat, sLng].join(",") : "",
    daddr: (dLat && dLng) ? [dLat, dLng].join(",") : "",
    travelmode: mode,
    dirflg: (mode === "walking") ? "w" : "d"
  }, { addQueryPrefix: true });
  await Linking.openURL("http://maps." + ((Platform.OS === "ios") ? "apple" : "google") + ".com/maps" + query);
};
