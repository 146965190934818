import React from "react";
import {DrawerNavigationProp} from "@react-navigation/drawer";
import {Renderer, THREE} from "expo-three";
import {AppState, Button, Dimensions, Platform, SafeAreaView, Text, View} from "react-native";
import {addBackgroundBox, CustomOrbitControls} from "../toolkits/threeJShelpers";
import {ExpoWebGLRenderingContext, GLView} from "expo-gl";
import {loadFBXAsync} from "../toolkits/threeJSModelLoaders";

const Game: React.FC<{
  navigation: DrawerNavigationProp<any, any>
}> = () => {
  const [cameraState, setCameraState] = React.useState<THREE.Camera>();
  const [sceneState, setSceneState] = React.useState<THREE.Scene>();
  const [clients, setClients] = React.useState([]);
  const blue = React.useRef<THREE.Group>();
  const mixers = React.useRef<THREE.AnimationMixer[]>([]);
  const actions = React.useRef<THREE.AnimationAction[]>([]);
  const actionDurations = React.useRef<number[]>([]);
  const actionIndexPlaying = React.useRef<number>(0);

  const ws = React.useRef<WebSocket>();
  const appState = React.useRef('background');

  React.useEffect(() => {
    const subscribe = () => {
      ws.current = new WebSocket('ws://websockets.varie.me');
      ws.current.addEventListener('message', (message) => {
        const messageDataJSON = JSON.parse(message.data);
        console.log('Received', messageDataJSON);
        setClients(messageDataJSON.clients);
        if (['0', '1', '2'].includes(messageDataJSON.command)) playAction(Number(messageDataJSON.command));
        if (['ArrowLeft', 'ArrowRight'].includes(messageDataJSON.command) && blue.current) turnModel(blue.current, messageDataJSON.command);
        if (messageDataJSON.command == 'Enter') playAction(1);
        if (messageDataJSON.command == 'Space') playAction(2);
      });
    }
    const unsubscribe = () => {
      ws.current?.close();
    }

    const webSocketsSubscription = AppState.addEventListener("change", nextAppState => {
      if (appState.current.match(/inactive|background/) && nextAppState === "active") {
        console.log('sub')
        subscribe();
      } else {
        console.log('unsub')
        unsubscribe();
      }
      appState.current = nextAppState;
    });
    subscribe();

    const keyPressed = (e: KeyboardEvent) => {
      doAction(e.code);
    }
    document.addEventListener('keydown', keyPressed);

    return () => {
      webSocketsSubscription.remove();
      unsubscribe();
      document.removeEventListener('keydown', keyPressed);
    }
  }, []);

  let timeout: number = 0;
  let timeoutBackToIdle: NodeJS.Timeout;
  React.useEffect(() => {
    // Clear the animation loop when the component unmounts
    return () => clearTimeout(timeout);
  }, []);

  const playAction = React.useCallback((actionIndexToPlay: number) => {
    if (actionIndexPlaying.current != actionIndexToPlay) {
      if (timeoutBackToIdle) clearTimeout(timeoutBackToIdle);
      if ((actionIndexPlaying.current == 3 || actionIndexPlaying.current == 4) && blue.current) {
        actions.current[actionIndexPlaying.current].reset();
        blue.current.rotation.y += THREE.MathUtils.degToRad(actionIndexPlaying.current == 3 ? 90 : -90);
      }
      actions.current[actionIndexToPlay].reset();
      actions.current[actionIndexToPlay].play();
      actions.current[actionIndexPlaying.current].crossFadeTo(actions.current[actionIndexToPlay], 0.5, true);
      actionIndexPlaying.current = actionIndexToPlay;
      timeoutBackToIdle = setTimeout(() => playAction(0), actionDurations.current[actionIndexToPlay] * 1000);
    }
  }, []);

  const turnModel = React.useCallback((model: THREE.Group, direction: 'ArrowLeft' | 'ArrowRight') => {
    playAction(direction == 'ArrowLeft' ? 3 : 4);
  }, []);

  const doAction = React.useCallback((action: string) => {
    if (ws.current?.readyState !== WebSocket.CLOSED) {
      ws.current?.send(action);
    } else {
      playAction(Number(action));
    }
  }, []);

  return (
    <SafeAreaView style={{flex: 1, paddingTop: 20}}>
      <CustomOrbitControls camera={cameraState} scene={sceneState}>
        <GLView
          style={{ flex: 1 }}
          onContextCreate={async (gl: ExpoWebGLRenderingContext) => {
            const { drawingBufferWidth: width, drawingBufferHeight: height } = gl;
            const sceneColor = 0x1b1b19;

            const renderer = new Renderer({ gl });
            renderer.setSize(window.innerWidth, window.innerHeight)
            renderer.shadowMap.enabled = true
            renderer.setSize(width, height);
            renderer.setClearColor(sceneColor);

            const camera = new THREE.PerspectiveCamera(70, width / height, 0.01, 1000);
            setCameraState(camera);
            camera.position.set(0, 5, 5);
            camera.lookAt(new THREE.Vector3());

            const scene = new THREE.Scene();
            setSceneState(scene);
            // scene.fog = new THREE.Fog(sceneColor, 1, 10000);
            scene.add(new THREE.GridHelper(10, 10));
            // var axesHelper = new THREE.AxesHelper( 5 );
            // scene.add( axesHelper );

            const ambientLight = new THREE.AmbientLight(0x101010);
            scene.add(ambientLight);

            const pointLight = new THREE.PointLight(0xffffff, 1, 1000, 0.5);
            pointLight.position.set(0, 150, -150);
            scene.add(pointLight);

            const dirLight = new THREE.DirectionalLight(0xffffff, 1.4)
            dirLight.position.set( 100, 250,  250);
            dirLight.castShadow = true;
            scene.add(dirLight);


            const planeGeometry = new THREE.PlaneGeometry(10, 10)
            const plane = new THREE.Mesh(planeGeometry, new THREE.MeshPhongMaterial())
            plane.rotateX(-Math.PI / 2)
            plane.position.y = 0
            plane.receiveShadow = true
            scene.add(plane)


            addBackgroundBox(scene, 50, {
              nx: require("../../assets/images/posx.jpg.three"),
              ny: require("../../assets/images/posy.jpg.three"),
              nz: require("../../assets/images/posz.jpg.three"),
              px: require("../../assets/images/negx.jpg.three"),
              py: require("../../assets/images/negy.jpg.three"),
              pz: require("../../assets/images/negz.jpg.three"),
            });

            // // makes a sphere with texture
            // const texture = await loadAsync(require('./resources/posx.jpg'));
            // const geometry = new THREE.SphereGeometry(3, 50, 50, 0, Math.PI * 2, 0, Math.PI * 2);
            // const material = new THREE.MeshPhongMaterial({map: texture}); //envMap is interesting
            // const sphere = new THREE.Mesh(geometry, material);
            // scene.add(sphere);

            // const box = new THREE.Mesh(
            //   new THREE.BoxBufferGeometry(1.0, 1.0, 1.0),
            //   new THREE.MeshStandardMaterial({
            //     color: 0x00FF00,
            //   }));
            // box.castShadow = true;
            // box.position.y = 1;
            // box.position.x = 2;
            // box.position.z = 1;
            // scene.add(box);



            // // const gltf = await loadGLTFAsync('https://s3-us-west-2.amazonaws.com/s.cdpn.io/1376484/stacy_lightweight.glb');
            // const gltf = await loadGLTFAsync(require("./stacy_lightweight.glb"));
            // mixer = new THREE.AnimationMixer(gltf.scene);
            // const action = mixer.clipAction(gltf.animations[0]);
            // action.play();
            // scene.add(gltf.scene);



            // // const gltf = await loadGLTFAsync('https://raw.githubusercontent.com/miazga/three-expo-gltf-loader/main/assets/weapons/v_knife_karam/v_knife_karam.gltf');
            // const gltf = await loadGLTFAsync(require("./xbotRumbaDancing2.gltf"));
            // gltf.scene.scale.setScalar(0.02);
            // // // Animations don't work
            // // mixer = new THREE.AnimationMixer(gltf.scene);
            // // const action = mixer.clipAction(gltf.animations[0]);
            // // action.play();
            // scene.add(gltf.scene);



            const blueIdle = await loadFBXAsync(require("../../assets/models/blue-idle.fbx"));
            const blueMixer = new THREE.AnimationMixer(blueIdle)
            mixers.current.push(blueMixer);
            blue.current = blueIdle;

            const blueIdleAction = blueMixer.clipAction(blueIdle.animations[0]);
            const blueWave = await loadFBXAsync(require("../../assets/models/blue-action-waving.fbx"));
            const blueWaveAction = blueMixer.clipAction(blueWave.animations[0]);
            const blueJump = await loadFBXAsync(require("../../assets/models/blue-action-jump.fbx"));
            const blueJumpAction = blueMixer.clipAction(blueJump.animations[0]);
            const blueTurnLeft = await loadFBXAsync(require("../../assets/models/blue-action-turn-left.fbx"));
            const blueTurnLeftAction = blueMixer.clipAction(blueTurnLeft.animations[0]);
            blueTurnLeftAction.loop = THREE.LoopOnce;
            blueTurnLeftAction.clampWhenFinished = true;
            const blueTurnRight = await loadFBXAsync(require("../../assets/models/blue-action-turn-right.fbx"));
            const blueTurnRightAction = blueMixer.clipAction(blueTurnRight.animations[0]);
            blueTurnRightAction.loop = THREE.LoopOnce;
            blueTurnRightAction.clampWhenFinished = true;
            actions.current.push(blueIdleAction, blueWaveAction, blueJumpAction, blueTurnLeftAction, blueTurnRightAction);
            actionDurations.current.push(2, 1, 2, 1, 1);
            actions.current[0].play();
            blueIdle.scale.setScalar(0.02);
            blueIdle.traverse((object) => {
              object.castShadow = true;
            });
            scene.add(blueIdle);


            // // const obj = await loadOBJAsync('https://files.theflobrand.com/FLO_Cart.obj', 'https://files.theflobrand.com/FLO_Cart.mtl');
            // const obj = await loadOBJAsync(require("./sprinter-body.obj"), require('./sprinter-body-material.mtl'));
            // obj.scale.set(0.1, 0.1, 0.1);
            // obj.rotation.z = THREE.MathUtils.degToRad(270);
            // obj.rotation.x = THREE.MathUtils.degToRad(-90);
            // scene.add(obj)



            const clock = new THREE.Clock();
            function update() {
              // box.rotation.y += 0.05;
              // box.rotation.x += 0.025;
              mixers.current.map((mixer) => mixer.update(clock.getDelta()));
              // orbitControls.update()
            }

            // Setup an animation loop
            const render = () => {
              timeout = requestAnimationFrame(render);
              update();
              renderer.render(scene, camera);
              gl.endFrameEXP();
            };
            render();
          }}
        />
      </CustomOrbitControls>
      <View style={{position: 'absolute', bottom: 10, left: 10, maxWidth: Dimensions.get('window').width - 140, maxHeight: 200, backgroundColor: 'rgba(0,0,0,0.2)', width: '100%', overflow: 'scroll'}}>
        {clients.map((c, i) => (<Text key={i} style={{color: 'white'}}>Cutie #{i+1}</Text>))}
      </View>
      <View style={{position: 'absolute', bottom: 10, right: 70}}><Button title="wave" onPress={() => doAction('1')}/></View>
      <View style={{position: 'absolute', bottom: 10, right: 10}}><Button title="jump" onPress={() => doAction('2')}/></View>
    </SafeAreaView>
  );
}

export default Game;
