import React from "react";
import {MapPOICategoryType} from "./Types";

export const AddFundModalVisibleContext = React.createContext<[boolean, (v?: any) => void]>([false, () => {}]);
export const MapFiltersContext = React.createContext<[{ name: MapPOICategoryType, value: boolean }[], (v: any) => void]>([[], () => {}]);
export const RadiusFiltersContext = React.createContext<[number, (v: number) => void]>([0, () => {}]);

const Contexts: React.FC<{
  children: JSX.Element | JSX.Element[]
}> = ({children}) => {
  const [addFundModalVisible, setAddFundModalVisible] = React.useState(false);
  const addFundModalVisibleValue = React.useMemo<[boolean, (v: any) => void]>(() => [addFundModalVisible, setAddFundModalVisible], [addFundModalVisible]);

  const [mapFilters, setMapFilters] = React.useState<{ name: MapPOICategoryType, value: boolean }[]>([]);
  const mapFiltersValue = React.useMemo<[{ name: MapPOICategoryType, value: boolean }[], (v: { name: MapPOICategoryType, value: boolean }[]) => void]>(() => [mapFilters, setMapFilters], [mapFilters]);

  const [radiusFilter, setRadiusFilter] = React.useState<number>(21);
  const radiusFilterValue = React.useMemo<[number, (v: number) => void]>(() => [radiusFilter, setRadiusFilter], [radiusFilter]);

  return (
    <AddFundModalVisibleContext.Provider value={addFundModalVisibleValue}>
      <MapFiltersContext.Provider value={mapFiltersValue}>
        <RadiusFiltersContext.Provider value={radiusFilterValue}>
          {children}
        </RadiusFiltersContext.Provider>
      </MapFiltersContext.Provider>
    </AddFundModalVisibleContext.Provider>
  );
}

export default Contexts;
